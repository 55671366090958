import request from '@/utils/tq'
//实时数据
export function freeday(query) {
  return request({
    url: '/free/day',
    method: 'get',
    params: query
  })
}

