import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
// import store from '@/store'
// import myconfig from '@/config'
// import {  getSignature } from '@/utils/auth'
import qs from 'qs'

const baseURL = process.env.NODE_ENV === 'production'
  ? 'http://anbiao.akgxhn.com'
  : 'http://anbiao.akgxhn.com'

// create an axios instance
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: 10000, // request timeout,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
})
//'Content-Type': 'application/x-www-form-urlencoded'
// request interceptor
service.interceptors.request.use(function(config){
    if (config.method === 'post') {
      config.data = config.data
      config.data = qs.stringify(config.data)
    } else if (config.method === 'get') {
      config.params = config.params
    }
    return config
  },function(error){
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(function(response) {
    const res = response.data
    if (res.status !== 200) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
    
      return Promise.reject('error')
    } else {
      return response.data
    }
  },function(error){
    Message({
      message: '网络异常',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
