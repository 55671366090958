<template>
  <div style="height: 100%; width: 100%; overflow: hidden" class="bg">
    <div style="display: flex; justify-content: center">
      <div :style="fjw">
        <div class="mr" :style="transform">
          <div style="width: 1920px; height: 1080px">
            <!-- 头部 -->
            <div class="kb_top">
              <div class="kb_top_logo"></div>

              <h1>装配车间管理看板</h1>
              <div class="kb_date">
                {{ getnyrz.month }}月{{ getnyrz.date }}日 {{ getnyrz.day }}
                {{ getnyrz.hour }}:{{ getnyrz.minute }}
              </div>
            </div>
            <!--  数据看板  -->

            <div class="kb_rsh">
              <div class="kb_rsh_box renyuan">
                <div class="bc_box">
                  <div class="bc_name">人员信息</div>

                  <div class="bc_sj">
                    <div class="bc_sj_item">
                      <p class="bc_sj_sj qingse">{{ scdata.person.aq }}</p>
                      <p class="bc_sj_name">安全负责人</p>
                    </div>
                    <div class="bc_sj_item mid">
                      <p class="bc_sj_sj qingse">{{ scdata.person.sc }}</p>
                      <p class="bc_sj_name">生产负责人</p>
                    </div>
                    <div class="bc_sj_item">
                      <p class="bc_sj_sj">{{ scdata.person.num }}</p>
                      <p class="bc_sj_name">车间总人数</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb_rsh_box shengchan">
                <div class="bc_box">
                  <div class="bc_name">当前生产订单</div>

                  <div class="bc_sj">
                    <div class="bc_sj_item">
                      <p class="bc_sj_sj qingse">{{ dqdd.dq }}</p>
                      <p class="bc_sj_name">客户名称</p>
                    </div>
                    <div class="bc_sj_item mid">
                      <p class="bc_sj_sj qingse">{{ dqdd.wcd }}%</p>
                      <p class="bc_sj_name">项目进度</p>
                    </div>
                    <div class="bc_sj_item">
                      <p class="bc_sj_sj">{{ dqdd.xg }}</p>
                      <p class="bc_sj_name">排期客户</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb_rsh_box huanjing">
                <div class="bc_box">
                  <div class="bc_name">环境参数</div>

                  <div class="bc_sj">
                    <div class="bc_sj_item">
                      <p class="bc_sj_sj">{{ free.tem }}℃</p>
                      <p class="bc_sj_name">温度</p>
                    </div>
                    <div class="bc_sj_item mid">
                      <p class="bc_sj_sj">{{ free.humidity }}</p>
                      <p class="bc_sj_name">湿度</p>
                    </div>
                    <div class="bc_sj_item">
                      <p class="bc_sj_sj">{{ free.pressure }}hPa</p>
                      <p class="bc_sj_name">气压</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 表格 -->
            <div class="kb_biaoge">
              <div class="pqz">排期中</div>

              <div class="yyq">已逾期</div>
              <div class="table_box">
                <div class="table_head">
                  <div class="table_line">
                    <div
                      class="table_row"
                      v-for="(item, i) in table_head"
                      :key="i"
                    >
                      <div class="table__nr">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table_body_box">
                  <div class="table_body" :style="table_sty">
                    <div
                      class="table_line_box"
                      v-for="(item, i) in scdata.order"
                      :key="i"
                    >
                      <div class="table_line">
                        <div
                          class="table_row"
                          v-for="(jtem, j) in table_head"
                          :key="j"
                        >
                          <div
                            class="table__nr"
                            :class="getrowtype(item) ? 'off' : ''"
                            v-if="jtem.value != 'wcd'"
                          >
                            {{ item[jtem.value] }}
                          </div>

                          <div
                            class="table__nr"
                            v-else
                            :class="getrowtype(item) ? 'off' : ''"
                          >
                            {{
                              parseInt(
                                (parseInt(item.wc_num) /
                                  parseInt(item.sc_num)) *
                                  100
                              )
                            }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--底部  -->

            <div class="kb_db">
              <!-- 产品存货 -->
              <div class="kb_cpch">
                <div class="kb_num">
                  <p class="num">{{getnum}}</p>
                  <p class="name">总数</p>
                </div>
                <div id="cpch" style="height: 100%"></div>
              </div>
              <!-- 月总产品量统计 -->
              <div class="kb_cpltj">
                <div class="kb_yf">{{ getnyrz.year }}</div>
                <div id="cpltj" style="height: 100%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getnyrz } from "@/utils/index.js";
import { home } from "@/api/index.js";
import { freeday } from "@/api/tq.js";
export default {
  data() {
    return {
      screenWidth: 1920, //监听宽度fanhui.png fanner pumphouse
      screenHeight: 1080,
      getnyrz: {
        year: "2023",
        month: "08",
        date: "30",
        hour: "9",
        minute: "57",
        second: "57",
        day: "星期四",
      },
      free: {}, //天气
      scdata: {
        person: {},
        product: [],
        order: [],
        now: [],
        next: [],
        count: {},
      },
      table_head: [
        {
          name: "客户",
          value: "client",
        },
        {
          name: "产品名称",
          value: "pro_name",
        },
        {
          name: "要货日期",
          value: "yh_date",
        },
        {
          name: "生产数量",
          value: "sc_num",
        },
        {
          name: "完成数量",
          value: "wc_num",
        },
        {
          name: "计划完成时间",
          value: "jh_date",
        },
        {
          name: "完成度",
          value: "wcd",
        },
      ],

      table_top: 0,
      table_time: null,
      time: null,
      free_time: null,
    };
  },
  computed: {
    dqdd() {
      let dq = "---";
      let wcd = "---";
      let xg = "---";

      let now = this.scdata.now;
      let next = this.scdata.next;

      if (now.length > 0) {
        dq = now[0].client;
        wcd = parseInt(
          (parseInt(now[0].wc_num) / parseInt(now[0].sc_num)) * 100
        );
      }
      if (next.length > 0) {
        xg = next[0].client;
      }
      return { dq: dq, xg: xg, wcd: wcd };
    },
    getnum(){
       let num=0;

       this.scdata.product.map(item=>{
        num+=parseInt(item.pro_num)
       })

       return num;

    },
    table_sty() {
      return { height: `${this.table_hei}px`, top: `-${this.table_top}px` };
    },
    table_hei() {
      return 52 * this.scdata.order.length;
    },

    fjw() {
      let w = 1920 * this.tran;
      let h = 1080 * this.tran;
      return {
        // width:`${w}px`,
        width: `${w}px`,
        height: `${h}px`,
      };
    },
    tran() {
      let ix = this.screenHeight / 1080;
      let iy = this.screenWidth / 1920;
      let i = ix > iy ? iy : ix;
      return i;
    },
    transform() {
      return {
        transform: `scale(${this.tran})`,
      };
    },
  },
  methods: {
    getrowtype(e) {
      //判断当前列状态
      var date = new Date(e.jh_date);
      var time1 = date.getTime();

      var time2 = new Date().getTime();

      if (parseInt(e.wc_num) == parseInt(e.sc_num) || time1 > time2) {
        return false;
      } else {
        return true;
      }
    },
    async getfreeday() {
      //获取天气
      let cs = { appid: "62588783", appsecret: "2ynzwQB0", vue: 1 };
      const res = await freeday(cs);
      // console.log(res);
      this.free = res;
    },
    settable_top() {
      //向上翻页
      let top = this.table_top;
      let wid = this.table_hei;
      // console.log(wid);
      if (wid <= 208 || wid == 0) {
        return;
      }

      if (wid - 208 < top) {
        top = 0;
      } else {
        top += 208;
      }
      this.table_top = top;
    },
    async home() {
      //过去数据
      const res = await home();
      // console.log(res);
      this.scdata = res.data;
      this.setcpch(res.data.product);
      this.setcpltj(res.data.count);
    },
    settable_Time() {
      //列表轮询
      const _that = this;
      this.table_time = setInterval(function () {
        _that.settable_top();
        _that.home();
      }, 5000);
    },
    setcpltj(e) {
      let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (let i = 0; i < e.length; i++) {
        const item = e[i];
        data[i] = item.value;
      }

      var chartDom = document.getElementById("cpltj");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid:{
          top:90,
          bottom:60
        },
        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
          axisLabel:{color:'#E6E7F3', fontSize:18},
          axisLine: {
            lineStyle: {
              color: "#5F7999",
             
            },
          },

          offset: 10,
        },
        tooltip: {
          trigger: "axis",
        },
        yAxis: {
          type: "value",
          minInterval: 100,
          max: 300,
          nameGap: 26,
          offset: 26,
          splitLine: {
            lineStyle: { color: "rgba(95,121,153,0.2)", width: 1 },
          },
          axisLine: {
            lineStyle: {
              color: "#858CD5",
              fontSize: "18px",
              width: 1,
            },
          },
        },
        series: [
          {
            data: data,
            type: "bar",
            showBackground: true,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: " #36ACF7" },
                { offset: 1, color: "#3ED9A6" },
              ]),
              borderRadius: 500,
            },
            barWidth: 19,
            backgroundStyle: {
              color: "rgba(216,216,216,0.26)",
              borderRadius: 500,
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    setcpch(e) {
      //产品存货

      let data = {
        legendData: [],
        seriesData: [],
      };

      e.map((i) => {
        let item = i;
        data.legendData.push(item.pro_name);
        data.seriesData.push({
          name: item.pro_name,
          value: parseInt(item.pro_num),
        });
      });

      // let data = {
      //   legendData: ["可编程控制箱", "品", "名", "称"],
      //   seriesData: [
      //     { name: "可编程控制箱", value: 300 },
      //     { name: "品", value: 200 },
      //     { name: "名", value: 100 },
      //     { name: "称", value: 500 },
      //   ],
      // };
      var chartDom = document.getElementById("cpch");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          left: 260,
          top: 100,
          bottom: 25,
          data: data.legendData,
          itemWidth: 10,
          itemHeight: 10,
          borderRadius: 7,
          // padding: 20,
          itemGap: 26,
          textStyle: {
            color: "#E6E7F3",
            fontSize: 16,
          },
          formatter: function (name) {
            let num = 0;
            for (let i = 0; i < data.seriesData.length; i++) {
              const item = data.seriesData[i];
              if (name == item.name) {
                num = item.value;
              }
            }
            return `${name} ${num}`;
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            name: "产品",
            type: "pie",
            center: ["30%", "60%"],
            radius: ["40%", "60%"],
            data: data.seriesData,
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
  destroyed() {
    if (this.time) {
      clearInterval(this.time);
    }
    if (this.free_time) {
      clearInterval(this.free_time);
    }
    if (this.table_time) {
      clearInterval(this.table_time);
    }
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      console.log("高度");
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
    // this.setcpch();
    this.settable_Time();
    // this.setcpltj();
    let _that = this;
    this.time = setInterval(function () {
      // console.log(getnyrz());
      _that.getnyrz = getnyrz();
    }, 1000);

    this.free_time = setInterval(function () {
      // console.log(getnyrz());
      _that.getfreeday();
    }, 1080000);
    this.home();
    this.getfreeday();
  },
};
</script>

<style lang="scss" scoped>
.mr {
  height: 100%;
  // width: 1920px;
  display: flex;
  transform-origin: left top;
  position: relative;

  .kb_top {
    width: 100%;
    height: 102px;
    background: url(../assets/top_bg.png) no-repeat;
    background-size: cover;
    position: relative;
    .kb_top_logo {
      width: 188px;
      height: 46px;
      background: url(../assets/logo@2x.png) no-repeat;
      background-size: cover;
      position: absolute;
      left: 40px;
      top: 18px;
    }
    .kb_date {
      height: 30px;
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #c1ebfb;
      line-height: 30px;
      position: absolute;
      right: 46px;
      top: 23px;
    }
    h1 {
      text-align: center;
      font-size: 54px;
      font-family: PangMenZhengDao;
      font-weight: normal;
      color: #525252;
      line-height: 102px;
      background: linear-gradient(180deg, #00f6ff 0%, #1a46f7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .kb_rsh {
    width: 1840px;
    height: 206px;
    display: flex;
    justify-content: space-between;
    margin: 23px auto 0px;

    .kb_rsh_box {
      width: 594px;
      height: 206px;
      position: relative;
      .bc_box {
        position: absolute;
        top: 30px;
        right: 40px;
        width: auto;
        .bc_name {
          height: 48px;
          font-size: 34px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #e6e7f3;
          line-height: 48px;
          text-align: center;
        }
        .bc_sj {
          margin-top: 27px;
          display: flex;
          .bc_sj_item {
            padding: 0px 22px;
            position: relative;
            .bc_sj_sj {
              height: 37px;
              font-size: 26px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #e6e7f3;
              line-height: 37px;
              text-align: center;
            }
            .bc_sj_sj.qingse {
              color: #03dffe;
            }
            .bc_sj_name {
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e6e7f3;
              line-height: 22px;
              margin-top: 4px;
              text-align: center;
            }
          }
          .bc_sj_item.mid::after {
            content: "";
            height: 44px;
            left: 0px;
            top: 14px;
            width: 1px;
            position: absolute;
            background-color: #2342ce;
          }
          .bc_sj_item.mid::before {
            content: "";
            height: 44px;
            right: 0px;
            top: 14px;
            width: 1px;
            position: absolute;
            background-color: #2342ce;
          }
          .bc_sj_item:last-child {
            padding-right: 0px;
            padding-left: 22px;
          }
          .bc_sj_item:first-child {
            padding-right: 22px;
            padding-left: 0px;
          }
        }
      }
    }
    .renyuan {
      background: url(../assets/renyuan_bg.png) no-repeat;
      background-size: cover;
    }
    .shengchan {
      background: url(../assets/shengchan_bg.png) no-repeat;
      background-size: cover;
    }
    .huanjing {
      background: url(../assets/huanjing_bg.png) no-repeat;
      background-size: cover;
    }
  }
  .kb_biaoge {
    width: 1849px;
    height: 366px;
    overflow: hidden;
    position: relative;
    background: url(../assets/kehudd_bg.png) no-repeat;
    background-size: cover;
    margin: 28px 0px 0px 31px;
    .pqz {
      position: absolute;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e6e7f3;
      line-height: 25px;
      top: 19px;
      right: 171px;
    }
    .yyq {
      position: absolute;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e6e7f3;
      line-height: 25px;
      top: 19px;
      right: 61px;
    }
    .pqz::after,
    .yyq::after {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 2px;
      position: absolute;
      top: 6px;
      left: -22px;
    }
    .pqz::after {
      background: #dcddeb;
    }
    .yyq::after {
      background: #ef7844;
    }
    .table_box {
      width: 1768px;
      height: 248px;
      margin: 80px auto 0px;
      overflow: hidden;
      .table_head {
        height: 40px;
        position: relative;
        .table_line {
          display: flex;
          .table_row {
            padding: 0px 10px;
            flex: 1;
            .table__nr {
              height: 28px;
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #35b4c0;
              line-height: 28px;
              text-align: center;
            }
          }
        }
      }
      .table_head::before,
      .table_line_box::before {
        content: "";
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0px;
        position: absolute;
        background: linear-gradient(
          90deg,
          rgba(147, 187, 207, 0) 0%,
          #93bbcf 11%,
          #93bbcf 49%,
          #93bbcf 87%,
          rgba(147, 187, 207, 0) 100%
        );
        opacity: 0.64;
      }

      .table_body_box {
        height: 208px;
        overflow: hidden;
        position: relative;
        .table_body {
          position: absolute;
          transition: all 1s;
          width: 100%;
          left: 0px;

          .table_line_box:nth-child(odd) {
            background: linear-gradient(
              90deg,
              rgba(2, 2, 54, 0) 0%,
              #020236 22%,
              #020236 49%,
              #020236 77%,
              rgba(2, 2, 54, 0) 100%
            );
          }
          .table_line_box:hover {
            background: linear-gradient(
              90deg,
              rgba(2, 2, 54, 0) 0%,
              #020236 22%,
              #020236 49%,
              #020236 77%,
              rgba(2, 2, 54, 0) 100%
            );
          }
          .table_line_box {
            height: 52px;
            position: relative;
            .table_line {
              display: flex;
              .table_row {
                flex: 1;
                // height: 52px;
                .table__nr {
                  font-size: 18px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #e6e7f3;
                  line-height: 52px;
                  text-align: center;
                }
                .table__nr.off {
                  color: #ef7844;
                }
              }
            }
          }
        }
      }
    }
  }
  .kb_biaoge::before {
    content: "客户订单";
    // width: 88px;
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e6e7f3;
    line-height: 30px;
    position: absolute;
    top: 13px;
    left: 26px;
  }
  .kb_db {
    width: 1849px;
    height: 299px;
    margin: 19px 0px 0px 31px;
    display: flex;
    justify-content: space-between;
    .kb_cpch {
      height: 299px;
      width: 470px;
      background: url(../assets/cpch_bg.png) no-repeat;
      background-size: cover;
      position: relative;
      .kb_num {
        position: absolute;
        top: 143px;
        left: 79px;
        width: 122px;
        .num {
          height: 45px;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #3095e7;
          line-height: 45px;
          text-align: center;
        }
        .name {
          text-align: center;
          height: 30px;
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e6e7f3;
          line-height: 30px;
        }
      }
    }
    .kb_cpch::before {
      content: "产品存货占比";
      // width: 88px;
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e6e7f3;
      line-height: 30px;
      position: absolute;
      top: 13px;
      left: 26px;
    }
    .kb_cpltj {
      height: 299px;
      width: 1367px;
      background: url(../assets/cltj_bg.png) no-repeat;
      background-size: cover;
      position: relative;
      .kb_yf {
        position: absolute;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #e6e7f3;
        line-height: 25px;
        top: 17px;
        right: 49px;
      }
    }
    .kb_cpltj::before {
      content: "月总产品量统计";
      // width: 88px;
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e6e7f3;
      line-height: 30px;
      position: absolute;
      top: 13px;
      left: 26px;
    }
  }
  //  padding: 10px;
}
.bg {
  background: #081838;
}
</style>
