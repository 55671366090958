import request from '@/utils/request'
//实时数据
export function home(query) {
  return request({
    url: '/home',
    method: 'get',
    data: query
  })
}

