import 'babel-polyfill'
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import "@/assets/font/font.css"
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router:router,
  store:store,
  render: function(h) {
    return h(App)
  },
}).$mount('#app')
